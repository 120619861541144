<template>
	<article
		itemscope="itemscope"
		itemtype="https://schema.org/Product"
		class="product"
		data-testid="data-product-card"
		:data-index="index"
		data-product-card>
		<figure
			class="product__card-figure"
			:class="{ 'product__card-figure--mobile-hide': !productImage.value }">
			<img
				itemprop="image"
				:alt="product.name"
				:src="productImage.image"
				class="product__card-image"
				:class="{ 'product__card-image--manufacture': !productImage.value }"
				loading="lazy" />
		</figure>
		<section class="product__card-info">
			<figure
				v-if="product.displayManufactureIcon"
				class="product__manufacture-image">
				<img
					:alt="product.manufacturerName"
					:src="product.manufactureIconUrl" />
			</figure>
			<DsText
				v-if="product.displayManufacturerName"
				html-element="div"
				text-type="body-md"
				text-style="regular"
				>
				{{ product.manufacturerName }}
			</DsText>
			<DsText
				html-element="div"
				text-type="heading-md"
				text-style="bold"
				itemprop="name"
				class="product__card-header">
				{{ product.displayName }}
			</DsText>
			<DsText
				v-if="!hideDescription"
				itemprop="description"
				html-element="span"
				text-type="body-sm">
				<span v-html="product.descriptionListView"></span>
			</DsText>
		</section>
		<a
			:href="product.productUrl"
			itemprop="url"
			class="product__card-link"
			@click="onClick"
			>{{ product.displayName }}</a
		>
	</article>
</template>

<script>
	import { mapGetters } from 'vuex';
	import { DsText } from "@coloplast/design-system";
	import { track_select_item } from 'shared/services/tracking/productTrackingService';
	import { itemListIdAndName } from 'shared/settings/constants';


	export default {
		name: 'related-product-card',
		props: {
			product: {
				type: Object,
				required: true,
			},
			ecommerceTrackingParameters: {
				type: Object,
				required: true,
			},
			hideDescription: {
				type: Boolean,
				default: false,
			},
			trackClick: {
				type: Boolean,
				default: false,
			},
			index: {
				type: Number,
				default: undefined,
			},
			category: {
				type: String,
				default: '',
			},
			registerWithObserver: {
				type: Function,
				required: true,
			},
		},
		components: {
			DsText,
		},
		computed: {
			...mapGetters({
				currency: 'getCurrency',
				visitorID: 'getVisitorID',
			}),
			firstImage() {
				const image = this.product.productPageImagesUrl;

				if (image && image.length) return image[0];

				return null;
			},
			productImage() {
				const regex = /default-product-image/;
				const isDefaultImg = regex.test(this.firstImage);

				return this.firstImage
					? {
							image: !isDefaultImg
								? this.firstImage
								: this.product.manufactureIconUrl,
							value: !isDefaultImg,
					  }
					: {
							image: this.product.manufactureIconUrl,
							value: false,
					  };
			},
		},
		methods: {
			onClick() {
				if (this.trackClick) {
					console.log(JSON.stringify(this.ecommerceTrackingParameters))
					track_select_item(this.ecommerceTrackingParameters, this.product, itemListIdAndName.RELATED_PRODUCTS);
				}
			},
		},
		mounted() {
			this.registerWithObserver(this.$el);
		}
	};
</script>

import { createApp } from "vue";
import store from 'shared/store';
import ProductCardList from "shared/components/product/productCardList.vue";

const supportingProductsMount = document.getElementById('supporting-products');
if (supportingProductsMount) {
	const app = createApp({
		name: 'SupportingProductsApp'
	});
	app.use(store);
	app.component('product-card-list', ProductCardList);
	app.mount(supportingProductsMount);
}
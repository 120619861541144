<!-- This is the Related Products list on the product detail page -->
<template>
	<div class="product__list product__supporting-products">
		<related-product-card
			v-for="(product, index) in vm.baseViewModel.products"
			:key="product.Code"
			:product="product"
			:ecommerceTrackingParameters="
				this.vm.baseViewModel.globalTrackingParameters
			"
			:hide-description="true"
			:track-click="true"
			:index="index"
			:category="product.category"
			:register-with-observer="registerWithObserver"
		></related-product-card>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import RelatedProductCard from 'shared/components/product/relatedProductCard.vue';
import intersectionObserverMixin from 'shared/mixins/intersectionObserverMixin';
import { track_view_item_list } from 'shared/services/tracking/productTrackingService';
import { itemListIdAndName } from 'shared/settings/constants';

export default {
	mixins: [intersectionObserverMixin],
	components: {
		'related-product-card': RelatedProductCard,
	},
	props: {
		vm: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			currency: 'getCurrency',
		}),
		products() {
			return this.vm.baseViewModel?.products || [];
		},
	},
	methods: {
		onProductsObserved(productElements) {
			let observedProducts = [];
			productElements.forEach(({ target, isIntersecting }) => {
				if (!isIntersecting) {
					return;
				}
				// call the unregisterFromObserver method that comes from the observer mixin
				this.unregisterFromObserver(target);
				const i = Number(target.dataset.index);
				const product = this.vm.baseViewModel.products[i];
				product.index = i;
				observedProducts.push(product);
			});
			if (observedProducts.length > 0) {
				track_view_item_list({
					productsDto: this.vm.baseViewModel,
					observedProducts,
					itemListAndName: itemListIdAndName.RELATED_PRODUCTS,
				});
			}
		},
	},
	created() {
		// hook the callback up with the intersection observer created by the mixin
		this.intializeObserver(this.onProductsObserved, {
			threshold: 0.6,
		});
	},
};
</script>

<style lang="scss">
@use 'sass:math';
@import "multisite/styles/settings/settings";
@import "multisite/styles/tools/tools";

.product {
	&__supporting-products {
		display: flex;
		flex: 1 0 auto;
		margin-top: ds-space(7);

		&-area {
			background-color: ds-color('bg', 'decorative-1-subtle');
			padding: ds-space(9) 0;

			@include t-respond-to-min(medium) {
				padding: 30px 0 ds-space(12);
			}

			.product__info-row {
				width: 100%;
			}

			.product__list {
				flex-flow: row nowrap;
				overflow-x: scroll;

				@include t-respond-to-min(medium) {
					overflow-x: auto;
					flex-flow: row wrap;
					margin: 0 ds-space(5);
				}

				@include t-respond-to-max(medium) {
					padding: 0 ds-space(5);
				}

				.product {
					overflow: hidden;

					@include t-respond-to-max(medium) {
						flex: 1 0 auto;
						width: 258px;
						margin: 5px 12px 44px;
					}
				}
			}
		}

		&-headline {
			$mobileGutter: $s-base-gutter * math.div(2, 3);
			padding: $mobileGutter;
		}

		.product__container {
			max-width: t-map-value($s-site-width, 'max');
			margin: 0 auto;
			padding: 0 t-rem($s-site-gutter);
		}
	}
}
</style>
